@import "../../assets/scss/mixin_variables";

.breadcrumbsComponentContainer {
  .breadcrumb-item + .breadcrumb-item::before {
    content: ">";
  }
  .breadcrumb-item {
    a {
      text-decoration: none;
      font-size: 18px;
      color: #2d3748;
    }
    &.active {
      a {
        text-decoration: none;
        font-size: 18px;
        font-weight: bold;
      }
    }
  }
}
