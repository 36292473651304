@import "~bootstrap/scss/bootstrap";
@import "./assets/scss/fonts";
@import "./assets/scss/mixin_variables.scss";

body {
  font-family: "Inter";
  font-weight: normal;
  font-style: normal;
  background-color: $bodyBgColor;
}

body {
  &::-webkit-scrollbar {
    width: 6px;
    height: 6px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background: rgba(225, 225, 225, 0.2);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(225, 225, 225, 0.2);
  }
  &::-webkit-scrollbar-thumb:hover {
    background: rgba(225, 225, 225, 0.4);
  }
  &::-webkit-scrollbar-thumb:active {
    background: rgba(225, 225, 225, 0.4);
  }
}

.modal570w {
  max-width: 570px;
  .modal-content {
    border-radius: 15px;
    border: none;
    @include cardShadow();
  }
}

.cmnModalWrapper {
  text-align: center;
  padding: 20px;
  .contentWrap {
    width: 80%;
    margin: 0 auto;
  }
  .heading {
    font-size: 30px;
    color: #181818;
    font-weight: 900;
    margin-bottom: 20px;
  }
  .info {
    font-size: 18px;
    font-weight: 700;
    color: rgb(32 32 32 / 50%);
  }
  .actionBtnWrap {
    margin-top: 40px;
  }
  .actionBtn {
    width: 140px;
    height: 50px;
    margin: 0 10px;
    font-size: 16px;
    border-radius: 15px;
    text-decoration: none;
    &.tealBtn {
      color: $white;
      background-color: #18586c;
    }
    &.whiteBtn {
      color: #181818;
      font-weight: 600;
      border: 1px solid $black;
    }
    &.redBtn {
      color: $white;
      font-weight: 600;
      background-color: #e92e29;
      border: 1px solid #e92e29;
    }
  }
}

button {
  @include moveUp();
}

.cardWrap {
  margin-bottom: 15px;
}
