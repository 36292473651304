@import "../../assets/scss/mixin_variables";

.headingComponentContainer {
  padding: 10px 0;
  .heading {
    font-weight: bold;
    font-size: 24px;
    margin-bottom: 0;
  }
}
