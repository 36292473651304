@import "../../assets/scss/mixin_variables.scss";

.feedbacksAndRequestsPageContainer {
  .cardReportWrapper {
    margin: 15px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
      }
    }
    .title {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      color: #2d3748;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .reportCatergoryWrapper {
    margin: 35px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      text-align: center;
      height: 220px;
      display: flex;
      align-items: center;
      justify-content: center;
      &.fixing {
        background-color: #ff0000;
        .heading,
        .number {
          color: $white;
        }
      }
      &.feature {
        background-color: $green;
        .heading,
        .number {
          color: #181818;
        }
      }
      &.general {
        background-color: #7e35ab;
        .heading,
        .number {
          color: $white;
        }
      }
      &.other {
        background-color: #f7c663;
        .heading,
        .number {
          color: #181818;
        }
      }
    }
    .heading {
      font-size: 25px;
      font-weight: 900;
    }
    .number {
      font-size: 45px;
      font-weight: 900;
      margin: 0;
    }
    a {
      text-decoration: none;
    }
  }
}

.fixingBugsPageContainer,
.featureRequestPageContainer {
  .chatListWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      margin-bottom: 25px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .portrait {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin: 0 15px;
    }
    .name {
      font-size: 14px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      white-space: nowrap;
      .id {
        margin-left: 2px;
      }
    }
    .accountStatusLabel {
      padding: 4px;
      color: $white;
      font-size: 12px;
      font-weight: 600;
      border-radius: 8px;
      line-height: 20px;
      text-align: center;
      padding: 4px 10px;
      background-color: #cbd5e0;
      &.offline {
        background-color: #cbd5e0;
      }
      &.online {
        background-color: #48bb78;
      }
    }
    .viewBtn {
      color: #718096;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      &:active {
        color: #718096;
      }
    }
  }
}

.detailedFeatureRequestPageContainer {
  a.greyBtn {
    color: #2d3748;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: #d9d9d9;
    border: 1px solid #000000;
    border-radius: 8px;
    padding: 10px 15px;
    &:active {
      color: #2d3748;
      background-color: #d9d9d9;
      border: 1px solid #000000;
    }
  }
  button.greyBtn {
    color: #2d3748;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: #d9d9d9;
    border: 1px solid #000000;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    &:active {
      color: #2d3748;
      background-color: #d9d9d9;
      border: 1px solid #000000;
    }
  }
  button.tealBtn {
    color: $white;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 10px;
    text-decoration: none;
    background-color: #18586c;
    border: 1px solid #000000;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    &:active {
      color: $white;
      background-color: #18586c;
      border: 1px solid #000000;
    }
  }
  .cardReportWrapper {
    margin: 15px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
      }
    }
    .title {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      color: #2d3748;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .headingWrapper {
    margin: 50px 0 0 0;
    .heading {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .reportedCardInfoWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .heading {
      color: #a0aec0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .portrait {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin: 0 15px;
    }
    .name {
      font-size: 16px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      white-space: nowrap;
      .id {
        margin-left: 5px;
      }
    }
    .accountStatusLabel {
      padding: 4px;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      line-height: 20px;
      text-align: center;
      padding: 5px 15px;
      background-color: #cbd5e0;
      &.offline {
        background-color: #cbd5e0;
      }
      &.online {
        background-color: #48bb78;
      }
    }
  }
  .reportDetailsCardInfoWrapper {
    padding-bottom: 40px;
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .heading {
      color: #a0aec0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .infoBox {
      display: flex;
      justify-content: space-between;
      padding: 5px 0 15px 0;
      margin: 10px 0;
      border-bottom: 1px solid rgb(232 232 233 / 40%);
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      &:first-child {
        margin: 0;
        padding-top: 0;
      }
      .flexItem:first-child {
        width: 30%;
      }
      .flexItem:last-child {
        width: 68%;
        text-align: right;
      }
    }
    .label {
      color: #181818;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
      font-size: 14px;
    }
    .info {
      color: rgb(24 88 108 / 50%);
      font-weight: 600;
      font-size: 14px;
    }
    .actionBtnWrap {
      margin-top: 30px;
      text-align: right;
    }
  }
}
