@import "../../assets/scss/mixin_variables.scss";

.dashboardPageContainer {
  .cardAmountWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
      }
    }
    .title {
      font-size: 12px;
      font-weight: 600;
      color: #a0aec0;
    }
    .amount {
      font-size: 18px;
      font-weight: bold;
      color: #2d3748;
    }
    .percentage {
      font-size: 12px;
      font-weight: bold;
      margin-left: 3px;
      display: inline-block;
    }
    .positive {
      color: #48bb78;
    }
    .negative {
      color: #e53e3e;
    }
    .iconWrap {
      width: 45px;
      height: 45px;
      display: flex;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      background-color: $teal;
      svg {
        path {
          fill: $white;
        }
      }
    }
  }
}
