@mixin flexWrap() {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

@mixin flexbox($wrap: wrap, $justify: space-between, $align: center) {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

  -ms-flex-wrap: $wrap;
  flex-wrap: $wrap;

  -webkit-box-align: $align;
  -ms-flex-align: $align;
  align-items: $align;

  -webkit-box-pack: $justify;
  -ms-flex-pack: $justify;
  justify-content: $justify;
}

@mixin fitCover() {
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: top;
  object-position: top;
}

@mixin transition($args...) {
  -webkit-transition: $args;
  -o-transition: $args;
  transition: $args;
}

@mixin transform($value) {
  -webkit-transform: ($value);
  -moz-transform: ($value);
  -ms-transform: ($value);
  transform: ($value);
}

@mixin translateCenter() {
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

@mixin imgFluid() {
  width: 100%;
  height: 100%;
  max-width: 100%;
}

@mixin flc() {
  &::first-letter {
    text-transform: uppercase !important;
  }
}

@mixin btnReset() {
  border: none;
  outline: none;
  box-shadow: none;
}

@mixin moveUp() {
  -webkit-transition: -webkit-transform 0.1s ease;
  transition: -webkit-transform 0.1s ease;
  transition: transform 0.1s ease;
  transition: transform 0.1s ease, -webkit-transform 0.1s ease;
  &:hover {
    -webkit-transform: translateY(-2px);
    transform: translateY(-2px);
  }
}

@mixin iplaceholder($color: $grey) {
  @include transition(border 0.3s);
  &::-webkit-input-placeholder {
    color: $color;
  }
  &::-moz-placeholder {
    color: $color;
  }
  &:-ms-input-placeholder {
    color: $color;
  }
  &:-moz-placeholder {
    color: $color;
  }
}

@mixin textNowrap() {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

@mixin cardShadow() {
  box-shadow: 5px 5px 20px 0 rgba(0, 0, 0, 0.1);
}

@mixin maxFixedWidth($noFixed: false) {
  width: 100%;
  margin: 0 auto;
  position: fixed;
  max-width: 1000px;
  @if $noFixed {
    position: static;
  }
}

$media-small: "only screen and (max-width: 767px)";

$black: #000000;
$white: #ffffff;
$black1: #313131;
$teal: #4fd1c5;
$bodyBgColor: #f8f9fa;
$green: #acf0b3;

$zIdxHeader: 100;
$zIdxMyTaskHeader: 100;
$zIdxFooterMenu: 100;
$zIdxLoader: 1000;
