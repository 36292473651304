@import "../../../assets/scss/mixin_variables.scss";

.signInPageContainer {
  background-color: $teal;
  height: 100vh;
  .singInFormWrapper {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $white;
    padding: 50px;
    border-radius: 15px;
  }
  .headerWrap {
    margin-bottom: 50px;
    text-align: center;
  }
  .heading {
    color: $teal;
    font-size: 32px;
    font-weight: bold;
  }
  .subHeading {
    color: #a0aec0;
    font-weight: 500;
    font-size: 14px;
  }
  .form-label {
    color: #2d3748;
    font-weight: 600;
    font-size: 14px;
  }
  .form-control {
    width: 350px;
    border-color: #e2e8f0;
    border-radius: 15px;
    padding: 12px 15px;
    @include iplaceholder(#a0aec0);
    &:focus {
      border-color: #e2e8f0;
      box-shadow: 0 0 0 0.1rem rgb(101 101 101 / 25%);
    }
  }
  .form-switch {
    margin: 20px 0 30px 0;
    .form-check-label {
      color: #2d3748;
      font-size: 12px;
      font-weight: 500;
      cursor: pointer;
    }
    .form-check-input:checked {
      background-color: $teal;
      border-color: $teal;
    }
    .form-check-input:focus {
      border-color: #4fd1c5;
      box-shadow: 0 0 0 0.25rem rgb(79 209 197 / 25%);
    }
  }
  .submit-btn {
    background-color: $teal;
    border-color: $teal;
    width: 100%;
    border-radius: 15px;
    padding: 10px;
    font-size: 14px;
    font-weight: bold;
    &:hover,
    &:active {
      background-color: darken($teal, 10) !important;
      border-color: darken($teal, 10) !important;
    }
  }
  .signUpLinkWrap {
    margin: 20px 0;
    text-align: center;
    .signUpTxt {
      color: #a0aec0;
      font-weight: 500;
      font-size: 14px;
      a {
        color: $teal;
      }
    }
  }
}
