@import "../../assets/scss/mixin_variables.scss";

.chatsPageContainer {
  .chatListWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      margin-bottom: 25px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .portrait {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin: 0 15px;
    }
    .name {
      font-size: 14px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      white-space: nowrap;
      .id {
        margin-left: 2px;
      }
    }
    .accountStatusLabel {
      padding: 4px;
      color: $white;
      font-size: 12px;
      font-weight: 600;
      border-radius: 8px;
      line-height: 20px;
      text-align: center;
      padding: 4px 10px;
      background-color: #cbd5e0;
      &.offline {
        background-color: #cbd5e0;
      }
      &.online {
        background-color: #48bb78;
      }
    }
    .viewBtn {
      color: #718096;
      font-size: 12px;
      font-weight: 600;
      text-decoration: none;
      &:active {
        color: #718096;
      }
    }
  }
}

.userChatsPageContainer,
.viewChatPageContainer {
  button.greyBtn {
    color: #2d3748;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3px;
    text-decoration: none;
    background-color: #d9d9d9;
    border: 1px solid #000000;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    &:active {
      color: #2d3748;
      background-color: #d9d9d9;
      border: 1px solid #000000;
    }
  }
  button.greenBtn {
    color: #2d3748;
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 3px;
    text-decoration: none;
    background-color: $green;
    border: 1px solid #000000;
    border-radius: 8px;
    padding-left: 15px;
    padding-right: 15px;
    margin-right: 10px;
    min-width: 120px;
    &:active {
      color: #2d3748;
      background-color: $green;
      border: 1px solid #000000;
    }
  }
  .cardReportWrapper {
    margin: 15px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
      }
    }
    .title {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
    }
    .number {
      color: #2d3748;
      font-size: 18px;
      font-weight: 600;
    }
  }
  .headingWrapper {
    margin: 50px 0 0 0;
    .heading {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
    }
  }
  .reportedCardInfoWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      margin-bottom: 20px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .heading {
      color: #a0aec0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .portrait {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin: 0 15px;
    }
    .name {
      font-size: 16px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      white-space: nowrap;
      .id {
        margin-left: 5px;
      }
    }
    .accountStatusLabel {
      padding: 4px;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      line-height: 20px;
      text-align: center;
      padding: 5px 15px;
      background-color: #cbd5e0;
      &.offline {
        background-color: #cbd5e0;
      }
      &.online {
        background-color: #48bb78;
      }
    }
  }
  .reportDetailsCardInfoWrapper {
    padding-bottom: 40px;
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .heading {
      color: #a0aec0;
      font-size: 14px;
      text-transform: uppercase;
      font-weight: 600;
      margin-bottom: 10px;
    }
    .infoBox {
      display: flex;
      justify-content: space-between;
      padding: 5px 0 15px 0;
      margin: 10px 0;
      border-bottom: 1px solid rgb(232 232 233 / 40%);
      &:last-child {
        border-bottom: none;
        padding-bottom: 0;
      }
      &:first-child {
        margin: 0;
        padding-top: 0;
      }
      .flexItem:first-child {
        width: 30%;
      }
      .flexItem:last-child {
        width: 68%;
        text-align: right;
      }
    }
    .label {
      color: #181818;
      font-weight: 600;
      line-height: 20px;
      display: inline-block;
      font-size: 14px;
    }
    .info {
      color: rgb(24 88 108 / 50%);
      font-weight: 600;
      font-size: 14px;
    }
    .actionBtnWrap {
      margin-top: 30px;
      text-align: right;
    }
  }
  .chatInfoWrapper {
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      margin-bottom: 25px;
      @include cardShadow();
      max-height: 800px;
      overflow: auto;
    }
    .chatBox {
      margin-bottom: 10px;
      .flexWrap {
        display: flex;
        width: 70%;
        align-items: center;
      }
      .textBox {
        background-color: rgb(235 232 233 / 40%);
        padding: 15px;
        border-radius: 50px;
        border-bottom-left-radius: 4px;
      }
      .text {
        color: #181818;
        font-size: 16px;
        font-weight: 500;
      }
      .time {
        color: rgb(32 32 32 / 50%);
        font-size: 12px;
        font-weight: 500;
        display: block;
        margin-top: 5px;
      }
      .deleteBtn {
        color: $white;
        background-color: #e92e29;
        text-decoration: none;
        border-radius: 8px;
        padding: 2px 10px;
        margin: 0 10px;
        font-size: 15px;
      }
    }
    .chatRightBox {
      .flexWrap {
        flex-direction: row-reverse;
        margin: 0 0 auto auto;
      }
      .textBox {
        background-color: #18586c;
        border-bottom-right-radius: 4px;
        border-bottom-left-radius: 50px;
      }
      .text {
        color: $white;
        font-weight: 400;
      }
      .time {
        text-align: right;
      }
    }
  }
  .searchInputWrapper {
    display: flex;
    margin-bottom: 25px;
    justify-content: flex-end;
    .searchWrap {
      display: flex;
      position: relative;
      border-radius: 15px;
      background-color: $white;
      border: 1px solid #e2e8f0;
      .iconWrap {
        position: absolute;
        padding: 13px 0 13px 13px;
      }
      .inputControl {
        border: none;
        outline: none;
        width: 450px;
        border-radius: 15px;
        padding: 13px 13px 13px 40px;
        @include iplaceholder(#a0aec0);
        &:focus {
          border-color: #e2e8f0;
          box-shadow: 0 0 0 0.1rem rgb(101 101 101 / 25%);
        }
      }
    }
  }
}
