@import "../../../assets/scss/mixin_variables.scss";

.usersDetailsPageContainer {
  $green: #acf0b3;
  .cardInfoWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .portrait {
      width: 40px;
      height: 40px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin: 0 15px;
    }
    .name {
      font-size: 16px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 0;
      white-space: nowrap;
      .id {
        margin-left: 5px;
      }
    }
    .accountStatusLabel {
      padding: 4px;
      color: $white;
      font-size: 14px;
      font-weight: 600;
      border-radius: 8px;
      line-height: 20px;
      text-align: center;
      padding: 5px 15px;
      background-color: #cbd5e0;
      &.pending {
        background-color: #cbd5e0;
      }
      &.accepted {
        background-color: #48bb78;
      }
      &.rejected {
        background-color: red;
      }
      &.suspended {
        background-color: orange;
      }
    }
    .timeBox {
      display: flex;
      align-items: center;
      .time {
        margin-left: 5px;
        color: #2d3748;
        font-size: 18px;
        font-weight: 600;
      }
    }
  }
  .selfieVerificationWrapper {
    .headingWrap {
      display: flex;
      align-items: center;
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
      .accountStatusLabel {
        color: $white;
        font-size: 14px;
        margin: 0 10px;
        font-weight: 600;
        padding: 5px 15px;
        line-height: 20px;
        border-radius: 8px;
        text-align: center;
        background-color: $white;
        &.pending {
          background-color: #f7c663;
        }
        &.verified {
          color: #181818;
          background-color: $green;
        }
      }
    }
  }
  .selfiePhotosWrapper {
    height: 100%;
    padding-bottom: 50px;
    .cardWrap {
      height: 100%;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .subHeading {
      color: #a0aec0;
      font-size: 18px;
      font-weight: 600;
      text-transform: uppercase;
      margin: 10px 0 20px 0;
    }
    .imgWrap img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 20px;
      object-position: center;
    }
    .selfieImgWrap {
      .imgWrap {
        height: 500px;
        max-width: 310px;
        padding-bottom: 30px;
      }
    }
    .uploadedImgWrap {
      .imgWrap {
        position: relative;
        margin-bottom: 30px;
        height: 220px;
      }
    }
    .removeBtn {
      position: absolute;
      right: -20px;
      top: -15px;
      padding: 0;
    }
    .uploadInfoWrap {
      .infoTxt {
        margin: 0;
        display: flex;
        font-size: 16px;
        font-weight: 600;
        color: rgb(24 88 108 / 50%);
      }
      svg {
        width: 35px;
        height: 30px;
        margin-right: 8px;
      }
    }
    .editBtn {
      font-size: 20px;
      background-color: #18586c;
      text-decoration: none;
      font-weight: 500;
      color: $white;
      padding: 12px 40px;
      border-radius: 15px;
      &.accept {
        background-color: #18586c;
      }
      &.reject {
        margin-left: 10px;
        background-color: #e92e29;
      }
      &.verified {
        color: #181818;
        background-color: $green;
        width: 175px;
        padding: 14px 0;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 0 auto auto;
        svg {
          margin-right: 10px;
        }
        &:active {
          color: #181818;
        }
      }
      &:active {
        color: $white;
      }
    }
  }
  .activityWrapper {
    padding-bottom: 40px;
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .infoBox {
      display: flex;
      justify-content: space-between;
      // &:not(:last-child) {
      padding: 5px 0 15px 0;
      margin: 10px 0;
      border-bottom: 1px solid rgb(232 232 233 / 40%);
      // }
      &:last-child {
        border-bottom: none;
      }
      .flexItem:first-child {
        width: 70%;
      }
      .flexItem:last-child {
        width: 25%;
        text-align: right;
        white-space: nowrap;
      }
      .label {
        color: #181818;
        font-weight: 600;
        line-height: 20px;
        display: inline-block;
        font-size: 14px;
      }
      .info {
        color: rgb(24 88 108 / 50%);
        font-weight: 600;
        font-size: 14px;
      }
    }
  }
  .basicDetailsWrapper {
    padding-bottom: 20px;
    .fluidHeight > div {
      height: 100%;
      padding-bottom: 15px;
    }
    .fluidHeight > div > .cardWrap {
      height: 100%;
    }
    .heading {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
  }
  .userDetailsWrapper {
    .infoBox {
      display: flex;
      justify-content: space-between;
      padding: 5px 0 15px 0;
      margin: 10px 0;
      border-bottom: 1px solid rgb(232 232 233 / 40%);
      &:last-child {
        border-bottom: none;
      }
      .flexItem:first-child {
        width: 70%;
      }
      .flexItem:last-child {
        width: 25%;
        text-align: right;
        white-space: nowrap;
      }
    }
    .label {
      color: #181818;
      font-size: 16px;
      display: block;
      font-weight: 600;
    }
    .info {
      color: rgb(24 88 108 / 50%);
      font-size: 16px;
      display: block;
      font-weight: 600;
    }
  }
  .userBioPassWrapper {
    .title {
      color: #181818;
      font-size: 16px;
      font-weight: bold;
      margin-bottom: 10px;
    }
    .txt {
      color: rgb(24 88 108 / 50%);
      font-size: 16px;
      display: block;
      font-weight: 600;
      line-height: 25px;
    }
  }
  .reportsWrapper {
    padding-bottom: 20px;
    .heading {
      color: #2d3748;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
      margin-bottom: 20px;
    }
    .infoBox {
      .flexWrap {
        display: flex;
        justify-content: space-between;
        .flexItem:first-child {
          width: 80%;
        }
        .flexItem:last-child {
          width: 18%;
          text-align: right;
          white-space: nowrap;
        }
      }
      .label,
      .info {
        color: rgb(24 24 24 / 50%);
        font-size: 16px;
        font-weight: bold;
      }
    }
    .viewBtnWrap {
      text-align: right;
      margin-top: 30px;
      .viewBtn {
        background-color: $green;
        color: rgba(24, 24, 24, 0.5);
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        border-radius: 8px;
      }
    }
  }
  .suspendAccountBtnWrap {
    text-align: right;
    margin-top: 30px;
    .suspendAccountBtn {
      text-decoration: none;
      color: $white;
      padding: 10px 20px;
      font-weight: 500;
      font-size: 20px;
      border-radius: 15px;
      &:active {
        color: $white;
      }
      &.inactive {
        background-color: rgb(196 196 196 / 60%);
      }
      &.active {
        background-color: #fa1010;
      }
    }
  }
}
