@import "../../../assets/scss/mixin_variables.scss";

.usersPageContainer {
  .cardAmountWrapper {
    margin: 20px 0 50px 0;
    .cardWrap {
      background-color: $white;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
    }
    p {
      margin: 0;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        margin-right: 10px;
      }
    }
    .title {
      font-size: 12px;
      font-weight: 600;
      color: #a0aec0;
    }
    .amount {
      font-size: 18px;
      font-weight: bold;
      color: #2d3748;
    }
    .percentage {
      font-size: 12px;
      font-weight: bold;
      margin-left: 3px;
      display: inline-block;
    }
    .positive {
      color: #48bb78;
    }
    .negative {
      color: #e53e3e;
    }
    .iconWrap {
      width: 45px;
      height: 45px;
      display: flex;
      border-radius: 12px;
      align-items: center;
      justify-content: center;
      background-color: $teal;
    }
  }
  .dataTableWrapper {
    .cardWrap {
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .heading {
      font-size: 18px;
      color: #2d3748;
      font-weight: bold;
      margin: 10px 0 10px 0;
    }
    .lnOUep {
      color: #a0aec0;
      font-weight: 600;
      font-size: 14px;
      text-transform: uppercase;
    }
    .rdt_TableRow {
      padding: 15px 0;
    }
    div {
      color: #718096;
      font-weight: 600;
      font-size: 14px;
    }
    .userInfoWrapper {
      padding-right: 20px;
      .flexWrap {
        display: flex;
        align-items: center;
        .flexItemOne {
          margin-right: 10px;
        }
      }
      .portrait {
        width: 40px;
        height: 40px;
        object-fit: cover;
        border-radius: 10px;
      }
      .name {
        font-size: 16px;
        color: #2d3748;
        margin-bottom: 0;
        font-weight: bold;
        white-space: nowrap;
        text-transform: capitalize;
      }
      .email {
        color: #718096;
        font-weight: 600;
        font-size: 14px;
        margin-bottom: 0;
        white-space: nowrap;
      }
    }
    .verificationStatusWrapper {
      .verificationStatusLabel {
        padding: 4px;
        color: $white;
        font-size: 14px;
        font-weight: 600;
        min-width: 100px;
        line-height: 20px;
        border-radius: 8px;
        text-align: center;
        background-color: $white;
        &.pending {
          background-color: #f7c663;
        }
        &.verified {
          color: #181818;
          background-color: #acf0b3;
        }
        &.rejected {
          background-color: #ff0000;
        }
      }
    }
    .accountStatusWrapper {
      .accountStatusLabel {
        padding: 4px;
        color: $white;
        font-size: 14px;
        min-width: 100px;
        font-weight: 600;
        border-radius: 8px;
        line-height: 20px;
        text-align: center;
        background-color: $white;
        &.pending {
          background-color: #cbd5e0;
        }
        &.accepted {
          background-color: #48bb78;
        }
        &.rejected {
          background-color: red;
        }
        &.suspended {
          background-color: orange;
        }
      }
    }
    .actionButtonWrapper {
      .editBtn {
        color: #718096;
        font-weight: 600;
        font-size: 14px;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    .searchInputWrapper {
      display: flex;
      justify-content: flex-end;
      .searchWrap {
        display: flex;
        position: relative;
        border-radius: 15px;
        background-color: $white;
        border: 1px solid #e2e8f0;
        margin-left: auto;
        .iconWrap {
          position: absolute;
          padding: 13px 0 13px 13px;
        }
        .inputControl {
          border: none;
          outline: none;
          border-radius: 15px;
          padding: 13px 13px 13px 40px;
          @include iplaceholder(#a0aec0);
          &:focus {
            border-color: #e2e8f0;
            box-shadow: 0 0 0 0.1rem rgb(101 101 101 / 25%);
          }
        }
      }
    }
    .toggleDeletedWrapper {
      display: flex;
      align-items: center;
      margin-top: 10px;
      label {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 600;
        color: #2d3748;
        input {
          margin-right: 10px;
        }
      }
    }
  }
}
