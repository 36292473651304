@import "../../assets/scss/mixin_variables";

.layoutContainer {
  padding: 30px 0;
  .layoutFlexWrap {
    display: flex;
    .layoutFlexItemOne {
      width: 270px;
    }
    .layoutFlexItemTwo {
      width: calc(100vw - 270px);
      padding: 0 13px;
    }
  }
}
