@import "../../assets/scss/mixin_variables";

.headerComponentContainer {
  padding: 5px 0;
  .flexContainer {
    display: flex;
    .flexItemOne {
      width: 300px;
    }
    .flexItemTwo {
      flex-grow: 1;
      padding: 0 13px;
    }
  }
  .searchInputWrapper {
    display: flex;
    justify-content: flex-end;
    .searchWrap {
      width: 100%;
      display: flex;
      position: relative;
      border-radius: 15px;
      background-color: $white;
      border: 1px solid #e2e8f0;
      .iconWrap {
        position: absolute;
        padding: 13px 0 13px 13px;
      }
      .inputWrap {
        flex: 1;
      }
      .inputControl {
        border: none;
        outline: none;
        width: 100%;
        border-radius: 15px;
        padding: 13px 13px 13px 40px;
        @include iplaceholder(#a0aec0);
        &:focus {
          border-color: #e2e8f0;
          box-shadow: 0 0 0 0.1rem rgb(101 101 101 / 25%);
        }
      }
    }
  }
}
