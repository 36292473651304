@import "../../assets/scss/mixin_variables.scss";

.profilePageContainer {
  .bgLayerWrap {
    position: relative;
    .textLayerWrap {
      top: 0;
      width: 100%;
      position: absolute;
      padding: 25px 23px;
    }
    .title {
      margin: 0;
      color: $white;
      font-size: 24px;
      font-weight: bold;
    }
    .bglayerImg {
      min-height: 180px;
    }
  }
  .profileEditWrap {
    width: 97%;
    z-index: 1;
    padding: 16px;
    margin: 0 auto;
    margin-top: -60px;
    position: relative;
    border-radius: 15px;
    background-color: rgb(255 255 255 / 95%);
    @include cardShadow();
    p {
      margin: 0;
    }
    .portrait {
      width: 80px;
      height: 80px;
      object-fit: cover;
      border-radius: 10px;
    }
    .flexWrap {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .flexItemOne {
        display: flex;
        margin-right: 10px;
        align-items: center;
        justify-content: space-between;
      }
    }
    .infoBox {
      margin-left: 20px;
    }
    .name {
      font-size: 16px;
      color: #2d3748;
      margin-bottom: 0;
      font-weight: bold;
      white-space: nowrap;
      text-transform: capitalize;
    }
    .userId {
      color: #718096;
      font-weight: 500;
      font-size: 14px;
      margin-bottom: 0;
    }
    .userImgBox {
      position: relative;
    }
    .editBtn {
      padding: 0;
      right: -10px;
      bottom: -5px;
      width: 30px;
      height: 30px;
      display: flex;
      position: absolute;
      border-radius: 12px;
      align-items: center;
      background-color: $white;
      justify-content: center;
      @include cardShadow();
      &:active {
        background-color: $white;
      }
    }
    .whiteBtn {
      width: 135px;
      display: flex;
      color: #2d3748;
      font-weight: 600;
      font-size: 13px;
      border-radius: 12px;
      align-items: center;
      @include cardShadow();
      text-decoration: none;
      justify-content: center;
      background-color: $white;
      text-transform: uppercase;
      svg {
        margin-right: 5px;
      }
      &:active {
        color: #2d3748;
        background-color: $white;
      }
    }
  }
  .settingWrap {
    width: 98%;
    display: flex;
    margin: 0 auto;
    margin-top: 30px;
    .cardWrap {
      height: 100%;
      border-radius: 15px;
      padding: 18px 21px;
      @include cardShadow();
      background-color: $white;
    }
    .notificationWrap,
    .profileInfoWrap {
      height: 100%;
      padding-bottom: 15px;
    }
    .heading {
      font-size: 18px;
      color: #2d3748;
      font-weight: bold;
      margin-bottom: 30px;
    }
  }
  .notificationWrap {
    .label {
      margin: 0;
      color: #a0aec0;
      font-size: 12px;
      font-weight: 600;
      text-transform: uppercase;
    }
    .form-switch {
      margin: 10px 0 30px 0;
      .form-check-label {
        color: #a0aec0;
        cursor: pointer;
        font-size: 12px;
        font-weight: 500;
        margin-left: 5px;
      }
      .form-check-input {
        width: 36px;
        height: 18px;
      }
      .form-check-input:checked {
        border-color: $teal;
        background-color: $teal;
      }
      .form-check-input:focus {
        border-color: #4fd1c5;
        box-shadow: 0 0 0 0.25rem rgb(79 209 197 / 25%);
      }
    }
  }
  .profileInfoWrap {
    .label,
    .txt {
      font-size: 13px;
      font-weight: bold;
    }
    .label {
      color: #718096;
    }
    .txt {
      color: #a0aec0;
    }
    .socialIcon {
      .btn {
        padding: 5px;
        margin-right: 5px;
      }
      svg {
        width: 20px;
        height: 25px;
      }
    }
  }
}
