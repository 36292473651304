@import "../../assets/scss/mixin_variables";

.sidebarComponentContainer {
  padding: 5px 20px 35px 20px;
  border-right: 3px solid #e2e8f0;
  min-height: calc(100vh - 60px);
  height: 100%;
  .userInfoWrapper {
    margin-bottom: 30px;
    .flexWrap {
      display: flex;
      align-items: center;
      .flexItem:last-child {
        margin-left: 10px;
      }
    }
    .circularNameInitials {
      font-weight: bold;
      font-size: 30px;
      width: 60px;
      height: 60px;
      background-color: #18586c;
      text-transform: uppercase;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
    }
    .name {
      color: #2d3748;
      font-weight: bold;
      font-size: 24px;
      text-transform: capitalize;
      margin: 0;
      line-height: 25px;
    }
    .email {
      color: #718096;
      font-weight: 500;
      font-size: 14px;
      margin: 0;
    }
  }
  .navLinkWrapper {
    .navLinkItem {
      display: block;
      margin-bottom: 10px;
      text-decoration: none;
      padding: 12px;
      &.active,
      &:hover {
        background-color: $white;
        border-radius: 15px;
        box-shadow: 2px 2px 20px 0 rgba(0, 0, 0, 0.05);
        .iconWrap {
          background-color: $teal;
          svg,
          path {
            fill: $white;
          }
          &.logoutIcon {
            svg,
            path {
              fill: $teal;
              stroke: $white;
            }
          }
        }
        .title {
          color: #2d3748;
        }
      }
      .flexWrap {
        display: flex;
        align-items: center;
      }
      .iconWrap {
        width: 36px;
        height: 36px;
        border-radius: 12px;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .title {
        margin-left: 10px;
        font-weight: bold;
        font-size: 16px;
        color: #a0aec0;
      }
    }
    .heading {
      color: #2d3748;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 18px;
      margin: 15px;
    }
  }
}
